import React, { useEffect, useRef, useCallback } from "react";
import { Link, Events } from "react-scroll";
import "./Header.scss";

const Header = () => {
  const sanitizeURL = useCallback((e: Event) => {
    if (window.location.hash) {
      window.history.replaceState("", document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", sanitizeURL);

    return () => document.removeEventListener("scroll", sanitizeURL);
  }, []);

  const navBarTogglerRef = useRef(null);
  useEffect(() => {
    const handleNavToggle = () => {
      const navbarTop = document.getElementById("header-navbar");
      navbarTop?.classList.toggle(
        "show",
        document
          .querySelector(".navbar-toggler")
          ?.getAttribute("aria-expanded") === "true"
      );
    };

    const handleScroll = () =>
      document
        .querySelector(".navbar")
        ?.classList.toggle("scroll", window.scrollY > 0);

    const handleLoad = () => handleScroll();

    document
      .querySelector(".navbar-toggler")
      ?.addEventListener("click", handleNavToggle);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleLoad);

    return () => {
      document
        .querySelector(".navbar-toggler")
        ?.removeEventListener("click", handleNavToggle);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <header id="header">
      <a href="#ds-main" className="visually-hidden-focusable">
        Skip to main content
      </a>

      <nav
        className="navbar navbar-expand-lg fixed-top"
        id="header-navbar"
        aria-label="Top navigation"
      >
        <div className="container-fluid px-0 px-sm-2">
          <div className="hstack flex-grow-1 justify-content-between">
            <a href="#" className="navbar-brand">
              <img
                src="assets/images/daysketcher-logo.svg"
                alt="Daysketcher Logo"
                width="100%"
                height="100%"
              />
            </a>
            <button
              className="navbar-toggler btn-link border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mt-2">
              <li className="nav-item nav-item-menu">
                <Link hashSpy className="nav-link" delay={0} to="features" offset={-50}>
                  Features
                </Link>
              </li>
              <li className="nav-item nav-item-menu">
                <Link className="nav-link" delay={0} to="benefits" offset={-50}>
                  Benefits
                </Link>
              </li>
              <li className="nav-item nav-item-menu">
                <Link className="nav-link" delay={0} to="team" offset={-50}>
                  Team
                </Link>
              </li>
              <li className="nav-item nav-item-menu">
                <Link className="nav-link" delay={0} to="faq" offset={-50}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
