import React from "react";
import Globe from "./Globe";
import "./DaysketcherWorld.scss";

const DaysketcherWorld = () => {
  return (
    <section
      id="dsWorld"
      className="container-fluid"
      aria-describedby="dsWorldHeading"
    >
      <span className="section-caption">Bigger Picture</span>
      <h2 id="dsWorldHeading" className="mb-sm-3">Daysketcher World</h2>

      <div className="grid text-center">
        <p className="g-col-md-8 g-start-md-3 g-col-12 text-secondary-emphasis mb-sm-4">
          Revive each memory and emotion, like you are there all over again. See
          how you have progress through live and how you grew. Daysketcher World
          is a place to interact with your memories. It could be your time
          capsule of your favorite things (journals, persons, posts, reels,
          music, food, hobbies).
        </p>
        {/* <div className="g-col-md-8 g-start-md-3 g-col-12 position-relative">
          <img
            src="assets/images/ds-sketch.jpeg"
            alt="Daysketcher World as a sketch on a paper"
            width="100%"
            height="100%"
          />
          <div className="card-sm card-bg-green">
            <i className="fa-solid fa-clock-rotate-left"></i>
            <span>
              <b>Go back in time</b> with our time capsule
            </span>
          </div>
          <div className="card-sm card-bg-blue">
            <i className="fa-solid fa-share"></i>
            <span>
              <b>Share a memory</b> with your friends and family or on your
              social media
            </span>
          </div>
          <div className="card-sm card-bg-orange">
            <i className="fa-solid fa-book-open"></i>
            <span>
              Give each memory a meaning through <b>journaling</b>
            </span>
          </div>
        </div> */}
   
          <Globe />
       </div>
    </section>
  );
};

export default DaysketcherWorld;
